.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 28px;
  line-height: 42px;
  height: 42px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 42px;
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .square {
    width: 41px;
    height: 41px;
    line-height: 41px;
  }
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .square {
    width: 27px;
    height: 27px;
    line-height: 27px;
    font-size: 19px;
  }

  .jumbotron .lead {
    display: none;
  }

  .jumbotron h1 {
    font-size: 1em;
  }
}

.square:focus {
  outline: none;
}

.square:hover {
  background-color: lightgray;
  cursor: pointer;
}

.Letter {
  position: relative;
  display: inline-block;
  width: 1.5em;
  font-size: 20px;
  font-family: 'Source Code Pro', 'Roboto Mono', Consolas, Monaco, monospace;
  font-weight: 600;
  text-align: center;
  line-height: 1.5em;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
}

.Letter:hover {
  background-color: #f8f9fa;
}

.Letter-highlight {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  z-index: -2;
  background-color: var(--theme-color);
  content: '';
  border-radius: 30px;
}

.Letter-highlight--joinN,
.Letter-highlight--joinNE,
.Letter-highlight--joinNW,
.Letter-highlight--joinE,
.Letter-highlight--joinS,
.Letter-highlight--joinSE,
.Letter-highlight--joinSW,
.Letter-highlight--joinW {
  z-index: -1;
  border-radius: 0;
}

.Letter-highlight--joinN {
  top: auto;
  bottom: 50%;
  height: 100%;
}

.Letter-highlight--joinNE {
  left: auto;
  right: 50%;
  width: 140%;
  transform: rotate(45deg);
  transform-origin: right center;
}

.Letter-highlight--joinNW {
  left: 50%;
  right: auto;
  width: 140%;
  transform: rotate(-45deg);
  transform-origin: left center;
}

.Letter-highlight--joinE {
  left: auto;
  right: 50%;
  width: 100%;
}

.Letter-highlight--joinS {
  top: 50%;
  bottom: auto;
  height: 100%;
}

.Letter-highlight--joinSE {
  left: auto;
  right: 50%;
  width: 140%;
  transform: rotate(-45deg);
  transform-origin: right center;
}

.Letter-highlight--joinSW {
  left: 50%;
  right: auto;
  width: 140%;
  transform: rotate(45deg);
  transform-origin: left center;
}

.Letter-highlight--joinW {
  left: 50%;
  right: auto;
  width: 100%;
}

.testcss {
  background-color: #99F3BD !important;
}

.wordlist li {
  list-style-type: none;
}
.wordlist {
  /*background-color: blue;*/
}
.board {
  display: inline-block;
}
.word {
  font-size: 2em;
}
.table td {
  /*padding: 0;*/
}
.selected {
  background-color: #28df99 !important;
}
.dropdown-category {
  margin: 10px;
}
